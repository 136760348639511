import Sidebar from "./Sidebar";

const Header = () => {

    const handleScrollDown = (id: string) => {
        // @ts-ignore
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <header className="container flex justify-between items-center">
            <a href="/">
                <img src="/logo.png" alt="" className="w-[74px] h-[70px]" />
            </a>
            <div className="md:flex gap-10 text-[20px] leading-[100%] hidden">
                <p className="text-[#9A9A9A] cursor-pointer" style={{ animation: "fade-in 1s" }}>Launchpad</p>
                <a
                    href="https://discord.gg/YVHAUP23Ef"
                    target="_blank"
                    className="hover:text-[black]/50 cursor-pointer"
                    style={{ animation: "fade-in 1s 0.5s backwards" }}
                    rel="noreferrer"
                >
                    Discord
                </a>
                <a
                    href="https://twitter.com/PlantersIO"
                    target="_blank"
                    className="hover:text-[black]/50 cursor-pointer"
                    style={{ animation: "fade-in 1s 1s backwards" }}
                    rel="noreferrer"
                >
                    Twitter
                </a>
                <p
                    onClick={() => handleScrollDown("services")}
                    className="hover:text-[black]/50 cursor-pointer"
                    style={{ animation: "fade-in 1s 1.5s backwards" }}
                >
                    Our Services
                </p>
                <p
                    onClick={() => handleScrollDown("about-us")}
                    className="hover:text-[black]/50 cursor-pointer"
                    style={{ animation: "fade-in 1s 2s backwards" }}
                >
                    About Us
                </p>
            </div>
            <Sidebar />
        </header>
    );
};

export default Header;