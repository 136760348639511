import { useState } from "react";
import LightBox from "./LightBox";

const Footer = () => {
    const [open, setOpen] = useState(false);
    const links = [
        {
            title: "Socials",
            items: [
                {
                    name: "Twitter",
                    link: "https://twitter.com/PlantersIO",
                    id: ""
                },
                {
                    name: "Discord",
                    link: "https://discord.gg/YVHAUP23Ef",
                    id: ""
                }
            ]
        },
        {
            title: "Collections",
            items: [
                {
                    name: "Potted Ps",
                    link: "https://magiceden.io/marketplace/pottedps",
                    id: ""
                }
            ]
        },
        {
            title: "Services",
            items: [
                {
                    name: "Art Studio",
                    link: "",
                    id: "art-studio",
                },
                {
                    name: "Launchpad",
                    link: "",
                    id: "launchpad",
                }
            ]
        }
    ];

    const handleScrollDown = (id: string) => {
        // @ts-ignore
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <footer className="flex flex-col sm:py-[50px] py-[20px] sm:pb-[15px] items-center bg-primary z-10 relative rounded-t-[60px] gap-10">
            <div className="container flex justify-between items-center sm:flex-row flex-col gap-5">
                <div className="flex lg:gap-20 gap-10">
                    <a href="/" className="p-[15px] rounded-[20px]">
                        <img src="/logo.png" alt="" className="w-[52px]" />
                    </a>
                    <div className="md:flex hidden lg:gap-20 gap-10 text-[20px] leading-[100%]">
                        {links.map(linkItem => (
                            <div className="flex flex-col gap-4" key={linkItem.title}>
                                <p className="font-heavy font-extrabold">{linkItem.title}</p>
                                <div className="flex flex-col gap-2">
                                    {linkItem.items.map(item => (
                                        <div
                                            key={item.name}
                                            className="hover:text-[black]/50 cursor-pointer"
                                            onClick={() => {
                                                if (!item.link) {
                                                    handleScrollDown(item.id);
                                                }
                                            }}
                                        >
                                            {item.link ?
                                                <a
                                                    href={item.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {item.name}
                                                </a> :
                                                <p>{item.name}</p>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <button
                    className="w-fit flex items-center gap-5 px-10 py-5 rounded-[50px] bg-white hover:bg-white/60 lg:text-[30px] text-[20px] leading-[100%]"
                    onClick={() => setOpen(true)}
                >
                    Apply now
                </button>
            </div>
            <div className="w-full text-center text-[15px] leading-[100%] text-white">Pixel Factory 2023</div>
            {open && <LightBox onClose={() => setOpen(false)} />}
        </footer>
    );
};

export default Footer;