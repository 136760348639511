import { useState } from "react";
import { CloseIcon, StackIcon } from "./svgs";


const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const handleScrollDown = (id: string) => {
        // @ts-ignore
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth'
        });
    }
    return (
        <div className="md:hidden">
            {!isOpen ? (
                <button className="top-4 right-4" onClick={() => setIsOpen(!isOpen)}>
                    <StackIcon />
                </button>
            ) : (
                <button className="fixed top-7 right-8 z-[100]" onClick={() => setIsOpen(!isOpen)}>
                    <CloseIcon />
                </button>
            )}
            <div className={`z-[60] top-0 right-0 fixed bg-white w-full h-full p-10 ${isOpen ? "translate-x-0" : "translate-x-full"} ease-in-out duration-300`}>
                <div className="h-full flex flex-col justify-center items-center gap-10 text-[20px] leading-[100%]">
                    <p className="text-[#9A9A9A] cursor-pointer" style={{ animation: "fade-in 1s" }}>Launchpad</p>
                    <a
                        href="https://discord.gg/YVHAUP23Ef"
                        target="_blank"
                        className="hover:text-[black]/50 cursor-pointer"
                        style={{ animation: "fade-in 1s 0.5s backwards" }}
                        rel="noreferrer"
                    >
                        Discord
                    </a>
                    <a
                        href="https://twitter.com/PlantersIO"
                        target="_blank"
                        className="hover:text-[black]/50 cursor-pointer"
                        style={{ animation: "fade-in 1s 1s backwards" }}
                        rel="noreferrer"
                    >
                        Twitter
                    </a>
                    <p
                        onClick={() => { setIsOpen(false); handleScrollDown("services")}}
                        className="hover:text-[black]/50 cursor-pointer"
                        style={{ animation: "fade-in 1s 1.5s backwards" }}
                    >
                        Our Services
                    </p>
                    <p
                        onClick={() => { setIsOpen(false); handleScrollDown("about-us")}}
                        className="hover:text-[black]/50 cursor-pointer"
                        style={{ animation: "fade-in 1s 2s backwards" }}
                    >
                        About Us
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
