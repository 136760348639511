import Header from "components/Header";
import LightBox from "components/LightBox";
import StaggerText from "components/StaggerText";
import { ArrowRightIcon } from "components/svgs";
import { useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";

const HeroSection = () => {
    const ref = useRef<any>();
    const { inViewport, enterCount } = useInViewport(ref);
    const [completed, setCompleted] = useState(false);
    const [open, setOpen] = useState(false);
    return (
        <section className="section items-center" ref={ref}>
            <div className="fixed z-[100] left-0 top-0 w-full flex justify-center py-10 bg-white/80">
                <Header />
            </div>
            <div className="container flex justify-end z-10 sm:mx-10 mx-5">
                <div className="flex flex-col">
                    <StaggerText
                        onComplete={() => setCompleted(true)}
                        animate={inViewport && enterCount === 1}
                        className={"font-heavy md:text-[120px] text-[80px] leading-[100%] font-extrabold " + (!inViewport ? "opacity-0" : "")}
                        contentString="Pixel Factory"
                    />
                    <StaggerText
                        animate={inViewport && enterCount === 1 && completed}
                        className={"md:text-[50px] text-[30px] leading-[51px] max-w-3xl mt-4 " + (!completed ? "opacity-0" : "")}
                        contentString="The multi-chain one stop shop for everything Pixels."
                        speed={0.01}
                    />
                    <button
                        className="w-fit flex items-center gap-5 px-10 py-5 rounded-[50px] bg-secondary hover:bg-secondary/80 mt-10 sm:text-[30px] text-[20px] leading-[100%]"
                        onClick={() => setOpen(true)}
                    >
                        Apply now <ArrowRightIcon />
                    </button>
                </div>
            </div>
            <div className="animate-left-to-right absolute left-0 bottom-0 w-full">
                <div className="h-[30px] bg-primary"></div>
                <div className="h-[30px] bg-[#FDFFB6]"></div>
                <div className="h-[30px] bg-secondary"></div>
                <div className="h-[30px] bg-[#BDB2FF]"></div>
            </div>
            <img src="/title_image.png" className="absolute left-0 bottom-0 xl:max-w-[750px] w-[50vw] aspect-square animate-fade-in" alt="" />
            {open && <LightBox onClose={() => setOpen(false)} />}
        </section>
    );
}

export default HeroSection;