export const ArrowRightIcon = ({ color = "black", ...rest }: { color?: string }) => (
    <svg width="64" height="15" viewBox="0 0 64 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 6.5C0.947715 6.5 0.5 6.94772 0.5 7.5C0.5 8.05228 0.947715 8.5 1.5 8.5L1.5 6.5ZM63.2071 8.20711C63.5976 7.81659 63.5976 7.18342 63.2071 6.7929L56.8431 0.428937C56.4526 0.0384126 55.8195 0.0384126 55.4289 0.428937C55.0384 0.819461 55.0384 1.45263 55.4289 1.84315L61.0858 7.50001L55.4289 13.1569C55.0384 13.5474 55.0384 14.1805 55.4289 14.5711C55.8195 14.9616 56.4526 14.9616 56.8431 14.5711L63.2071 8.20711ZM1.5 8.5L62.5 8.50001L62.5 6.50001L1.5 6.5L1.5 8.5Z" fill={color} />
    </svg>
);

export const ArrowDownIcon = ({ ...rest }) => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path d="M4.76777 1.23223C3.79146 0.255922 2.20854 0.255922 1.23223 1.23223C0.255922 2.20854 0.255922 3.79146 1.23223 4.76777L4.76777 1.23223ZM27 29.5C28.3807 29.5 29.5 28.3807 29.5 27L29.5 4.5C29.5 3.11929 28.3807 2 27 2C25.6193 2 24.5 3.11929 24.5 4.5V24.5H4.5C3.11929 24.5 2 25.6193 2 27C2 28.3807 3.11929 29.5 4.5 29.5L27 29.5ZM1.23223 4.76777L25.2322 28.7678L28.7678 25.2322L4.76777 1.23223L1.23223 4.76777Z" fill="black" />
    </svg>
);

export const StackIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h7" />
    </svg>
);

export const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#9A9A9A" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
);