/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useMemo, useRef } from "react";
import gsap from "gsap";
const StaggerText = ({
    className = "",
    contentString,
    animate,
    speed,
    onComplete
}: {
    className?: string,
    contentString: string,
    animate: boolean,
    speed?: number,
    onComplete?: () => void
}) => {
    const ref = useRef<any>();

    const content = useMemo(() => {
        const letters = [];
        for (let i = 0; contentString && i < contentString.length; i++) {
            letters.push(<span className="letter" key={"char" + i}>{contentString[i]}</span>);
        }
        return letters;
    }, [contentString]);

    useLayoutEffect(() => {
        if (!animate) return;
        const ctx = gsap.context(() => {
            const timeline = gsap.timeline({ onComplete: onComplete });
            timeline.from(".letter", { duration: 1, opacity: 0, stagger: speed || 0.03, ease: "power2.in" });
        }, ref);

        return () => ctx.revert();
    }, [animate]);

    return (
        <div ref={ref}>
            <div className={"stagger " + className}>{content}</div>
        </div>
    )
};

export default StaggerText;