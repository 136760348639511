import LightBox from "components/LightBox";
import StaggerText from "components/StaggerText";
import { ArrowRightIcon } from "components/svgs";
import { useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";

const ServiceBox = ({ id, title, content, style }: { id: string, title: string, content: string, style?: Object }) => {
    const ref = useRef<any>();
    const { inViewport, enterCount } = useInViewport(ref);
    const [completed, setCompleted] = useState(false);
    const [active, setActive] = useState(false);
    const [open, setOpen] = useState(false);
    return (
        <div
            id={id}
            ref={ref}
            className={"relative bg-white w-full min-h-[80vh] flex flex-col py-5 items-center justify-center rounded-[60px] hover:shadow-[_18px_20px_0px_5px_#9BF6FF] " + ((inViewport && enterCount === 1) ? "animate-fade-in" : "")}
            onMouseOver={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
            style={style}
        >
            <StaggerText
                className={"font-heavy sm:text-[60px] text-[35px] leading-[100%] font-extrabold " + (!inViewport ? "opacity-0" : "")}
                contentString={title}
                animate={inViewport && enterCount === 1}
                onComplete={() => setCompleted(true)}
            />
            <div className="w-[115px] h-[3px] bg-primary my-[27px]" />
            <StaggerText
                className={"sm:text-[25px] text-[20px] leading-[150%] text-center max-w-[500px] px-5 " + (!completed ? "opacity-0" : "")}
                contentString={content}
                animate={inViewport && enterCount === 1 && completed}
                speed={0.005}
            />

            <div className={"w-full flex justify-center " + (active ? "animate-fade-in" : "sm:opacity-0 opacity-100")}>
                <button
                    className="w-fit flex items-center gap-5 px-10 py-5 rounded-[50px] bg-primary hover:bg-primary/80 text-white mt-10 sm:text-[30px] text-[20px] leading-[100%]"
                    onClick={() => setOpen(true)}
                >
                    Apply now <ArrowRightIcon color="white" />
                </button>
            </div>
            {open && <LightBox onClose={() => setOpen(false)} />}
        </div>
    );
};

const ServicesSection = () => {
    return (
        <section className="section bg-primary sm:px-[54px] px-5 py-[60px] pb-[108px] h-full !grid lg:grid-cols-2 grid-cols-1 gap-[30px] rounded-b-[60px]" id="services">
            <ServiceBox
                id="art-studio"
                title="Art Studio"
                content="PixelFactory's creative studio designed to help navigate you in the pixel art ecosystem. Our studio is built for those that are looking for services such as Art Creation, Art Direction, Marketing, and so on. If this is an area of interest for you, apply now."
            />
            <ServiceBox
                id="launchpad"
                title="Launchpad"
                content="Our launchpad is designed to help market and push pixel collections out to the world of Web3. We are providing options for new founders / creators and Artists across all ecosystems. If you're looking to launch under the Px3 Brand, apply now."
                style={{ animationDelay: '0.5s' }}
            />
        </section>
    );
}

export default ServicesSection;