import StaggerText from "components/StaggerText";
import { ArrowDownIcon } from "components/svgs";
import { useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";

const OverlayLink = ({ content, link }: { content: string, link: string }) => {
    const [active, setActive] = useState(false);
    return (
        <a
            href={link}
            target="_blank"
            className="flex items-end min-h-[45px]"
            style={{ gap: active ? "25px" : "40px" }}
            onMouseOver={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
            rel="noreferrer"
        >
            <p
                style={{ fontSize: active ? "45px" : "30px" }}
                className="leading-[100%]"
            >
                {content}
            </p>
            <ArrowDownIcon style={{ width: active ? "34px" : "20px" }} />
        </a>
    )
}

const AboutUsSection = () => {
    const ref = useRef<any>();
    const { inViewport, enterCount } = useInViewport(ref);
    const [completed, setCompleted] = useState(false);
    return (
        <section className="section flex items-center overflow-hidden" id="about-us">
            <div className="container" ref={ref}>
                <StaggerText
                    className={"font-heavy sm:text-[60px] text-[40px] leading-[100%] font-extrabold " + (!inViewport ? "opacity-0": "")}
                    contentString="About Us"
                    animate={inViewport && enterCount === 1}
                    onComplete={() => setCompleted(true)}
                />
                <StaggerText
                    className={"sm:text-[30px] text-[20px] leading-[150%] mt-[30px] max-w-[728px] " + (!completed ? "opacity-0" : "")}
                    contentString="The team at Px3 have a passion for Web3 and have been here since 2021. Our mission; To have influence in Web3 as it grows past everyone's expectation and position our clients / products for the future."
                    animate={inViewport && enterCount === 1 && completed}
                    speed={0.005}
                />
                <div className="mt-10 flex flex-col gap-3">
                    <OverlayLink content="Potted Ps" link="https://magiceden.io/marketplace/pottedps" />
                    <OverlayLink content="Discord" link="https://discord.gg/YVHAUP23Ef" />
                    <OverlayLink content="Twitter" link="https://twitter.com/PlantersIO" />
                </div>
            </div>

            <div className="absolute -bottom-[17vw] right-0">
                <img src="/conveyorlong.gif" alt="" className="w-[60vw] h-[60vw]" />
            </div>
        </section>
    );
}

export default AboutUsSection;