import Footer from "components/Footer";
import AboutUsSection from "components/sections/AboutUsSection";
import HeroSection from "components/sections/HeroSection";
import ServicesSection from "components/sections/ServicesSection";

const HomePage = () => {
    return (
        <main className='overflow-x-hidden'>
            <HeroSection />
            <ServicesSection />
            <AboutUsSection />
            <Footer />
        </main>
    );
};

export default HomePage;