import { CloseIcon } from "./svgs";
import { Widget } from '@typeform/embed-react'

const LightBox = ({ onClose }: { onClose: () => void }) => {
    return (
        <div className="fixed z-[100] inset-0 w-full h-full bg-white/80 flex items-center justify-center px-5 animate-fade-in" onClick={(e) => { onClose(); }}>
            <div className="max-w-2xl w-full max-h-[850px] h-full bg-white shadow-[_5px_10px_10px_5px_#9B979740] rounded-[20px] relative" onClick={(e) => e.stopPropagation()}>
                <button className="absolute right-10 top-10" onClick={onClose}>
                    <CloseIcon />
                </button>

                <div className="flex flex-col w-full h-full items-center justify-center gap-[30px] px-5">
                    <p className="text-[35px] text-center font-heavy">Apply for services</p>
                    <Widget 
                        id="VTAT4iI4" 
                        iframeProps={{ title: 'Launchpad application form' }} 
                        opacity={100} 
                        medium="snippet"
                        transitiveSearchParams 
                        style={{ width: '100%', height: '650px' }}
                    />
                </div>
            </div>
        </div>
    );
}

export default LightBox;